var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"margin-left":"20%","margin-right":"4.5%","width":"60%"}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"opacity":_vm.opacity,"indeterminate":"","color":"white"}})],1),_c('v-card',[_c('v-card-title',[_vm._v(" Report Outstanding Kwitansi ")]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.items,"label":"Nama Customer","item-text":"CONCAT_NAMA","item-value":"customer_number","outlined":""},model:{value:(_vm.dataForm.nomorCustomer),callback:function ($$v) {_vm.$set(_vm.dataForm, "nomorCustomer", $$v)},expression:"dataForm.nomorCustomer"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_tipekwitansi,"label":"Tipe Kwitansi","item-text":"CONCAT_NAMA","item-value":"KODE","outlined":""},model:{value:(_vm.dataForm.tipekwitansi),callback:function ($$v) {_vm.$set(_vm.dataForm, "tipekwitansi", $$v)},expression:"dataForm.tipekwitansi"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_pph,"label":"Tipe PPh","item-text":"item_text","item-value":"item_value","outlined":""},model:{value:(_vm.dataForm.tipePPh),callback:function ($$v) {_vm.$set(_vm.dataForm, "tipePPh", $$v)},expression:"dataForm.tipePPh"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","align-self":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","required":"","rules":_vm.startDateRules},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogDateDari),callback:function ($$v) {_vm.dialogDateDari=$$v},expression:"dialogDateDari"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialogDateDari = false}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","align-self":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","required":"","rules":_vm.endDateRules},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogDateSampai),callback:function ($$v) {_vm.dialogDateSampai=$$v},expression:"dialogDateSampai"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialogDateSampai = false}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","align-self":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Pelunasan","prepend-icon":"mdi-calendar","readonly":"","outlined":"","required":""},model:{value:(_vm.date_pelunasan),callback:function ($$v) {_vm.date_pelunasan=$$v},expression:"date_pelunasan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialogDatePelunasan),callback:function ($$v) {_vm.dialogDatePelunasan=$$v},expression:"dialogDatePelunasan"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dialogDatePelunasan = false}},model:{value:(_vm.date_pelunasan),callback:function ($$v) {_vm.date_pelunasan=$$v},expression:"date_pelunasan"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nomor Kwitansi","required":"","outlined":""},model:{value:(_vm.dataForm.nomorKwitansi),callback:function ($$v) {_vm.$set(_vm.dataForm, "nomorKwitansi", $$v)},expression:"dataForm.nomorKwitansi"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nomor BBT","required":"","outlined":""},model:{value:(_vm.dataForm.nomorBBT),callback:function ($$v) {_vm.$set(_vm.dataForm, "nomorBBT", $$v)},expression:"dataForm.nomorBBT"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"ma-1",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.cetakReport()}}},[_vm._v(" Cetak Report ")]),_c('v-btn',{staticClass:"ma-1",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"error"},on:{"click":function($event){return _vm.clearForm()}}},[_vm._v(" Clear Data ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }