<template>
    <v-container style="margin-left:20%; margin-right:4.5%; width: 60%">
        <v-overlay :value="loading">
            <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white">
            </v-progress-circular>
        </v-overlay>
        <v-card>
            <v-card-title>
                Report Outstanding Kwitansi
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" lazy-validation>
                        <!-- <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-autocomplete
                                      v-model="dataForm.namaCustomer"
                                      :items="items"
                                      label="Nama Customer"
                                    ></v-autocomplete>                           
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                      v-model="dataForm.nomorKwitansi"
                                      label="Nomor Kwitansi"
                                      required
                                    ></v-text-field>                          
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                      v-model="dataForm.nomorKwitansi"
                                      label="Nomor BBT"
                                      required
                                    ></v-text-field>                           
                                </v-col>
                            </v-row> -->

                        <!-- <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-autocomplete
                                      v-model="dataForm.namaCustomer"
                                      :items="items"
                                      label="Jenis Transaksi"
                                    ></v-autocomplete>                           
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                      v-model="dataForm.nomorKwitansi"
                                      label="Tipe Dokumen"
                                      required
                                    ></v-text-field>                          
                                </v-col>
                            </v-row> -->
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete v-model="dataForm.nomorCustomer" :items="items" label="Nama Customer"
                                    item-text="CONCAT_NAMA" item-value="customer_number" outlined>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete v-model="dataForm.tipekwitansi" :items="items_tipekwitansi"
                                    label="Tipe Kwitansi" item-text="CONCAT_NAMA" item-value="KODE" outlined>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete v-model="dataForm.tipekwitansi" :items="items_tipekwitansi"
                                    label="Tipe Kwitansi" item-text="CONCAT_NAMA" item-value="KODE" outlined>
                                </v-autocomplete>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col sm="6" md="12">
                                <v-autocomplete v-model="dataForm.tipePPh" :items="items_pph" label="Tipe PPh"
                                    item-text="item_text" item-value="item_value" outlined>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4" align-self="center">
                                <v-menu v-model="dialogDateDari" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="start_date" label="Start Date" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" outlined required :rules="startDateRules">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="start_date" @input="dialogDateDari = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" align-self="center">
                                <v-menu v-model="dialogDateSampai" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="end_date" label="End Date" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" outlined required :rules="endDateRules">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="end_date" @input="dialogDateSampai = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" align-self="center">
                                <v-menu v-model="dialogDatePelunasan" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="date_pelunasan" label="Tanggal Pelunasan"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined required>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_pelunasan"
                                        @input="dialogDatePelunasan = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="6" md="12">
                                <v-text-field v-model="dataForm.nomorKwitansi" label="Nomor Kwitansi" required outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="6" md="12">
                                <v-text-field v-model="dataForm.nomorBBT" label="Nomor BBT" required outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="justify-center">
                            <v-btn class="ma-1" :loading="loading" :disabled="loading" color="primary"
                                @click="cetakReport()">
                                Cetak Report
                            </v-btn>
                            <v-btn class="ma-1" :loading="loading" :disabled="loading" color="error" @click="clearForm()">
                                Clear Data
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>

import axios from 'axios'
import { mapGetters } from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
export default {
    data: () => ({
        items: [],
        dataForm: [],
        items_tipekwitansi: [],
        opacity: 0.5,
        loading: false,
        dialogDateDari: false,
        dialogDateSampai: false,
        dialogDatePelunasan: false,
        start_date: '',
        end_date: '',
        date_pelunasan: '',
        items_pph: [
            { item_text: 'PPh Final', item_value: 'pphFinal' },
            { item_text: 'PPh 23', item_value: 'pph23' }
        ],
        startDateRules: [
            v => !!v || 'Tanggal Awal Harus di Isi!'
        ],
        endDateRules: [
            v => !!v || 'Tanggal Akhir Harus di Isi!'
        ]
    }),
    computed: {
        ...mapGetters({
            user: 'user',
        })
    },
    mounted() {
        // alert(this.user.id);
        axios({
            method: 'post',
            url: '/v1/getCustomerperuser',
            data: {
                user_id: this.user.id,
            },
        })
            .then(response => {
                this.items = response.data.data;
                // console.log(this.items);
                // window.location.reload();
            })
            .catch(error => {
                console.log(error.response)
            })
        axios({
            method: 'get',
            url: 'getTipeKwitansi'
        })
            .then(response => {
                this.items_tipekwitansi = response.data.data;
            })
            .catch(error => {
                console.log(error.response)
            })
    },
    created() {
        // let uri3 = `v1/roles`;
        // axios.get(uri3).then((response) => {
        //     this.role_id = response.data.data;
        // });
    },
    methods: {
        clearForm() {
            this.dataForm = [];
            this.start_date = '';
            this.end_date = '';
            this.date_pelunasan = '';
        },
        cariKodeNamaSupplier(item) {
            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].customer_number === item) {
                    return this.items[i].CONCAT_NAMA;
                }
            }
        },
        formatCurrency(value) {
            var formatter = new Intl.NumberFormat('en-US', {
            });

            return formatter.format(value); /* $2,500.00 */
        },
        cetakReport() {
            // alert(this.$refs.form.validate());
            if (this.$refs.form.validate()) {
                this.loading = true;
                var grand_total_dpp = 0;
                var grand_total_pph = 0;
                axios({
                    method: 'post',
                    url: 'getDataReportOutstandingUser',
                    data: {
                        nomor_customer: this.dataForm.nomorCustomer,
                        tipe_kwitansi: this.dataForm.tipekwitansi,
                        nomor_kwitansi: this.dataForm.nomorKwitansi,
                        nomor_bbt: this.dataForm.nomorBBT,
                        start_date: this.start_date,
                        end_date: this.end_date,
                        tipe_pph: this.dataForm.tipePPh,
                        date_pelunasan: this.date_pelunasan
                    }
                })
                    .then(response => {
                        var data = response.data.data;
                        var i = 1;
                        var dataRow = [];
                        data.forEach(element => {
                            var bodyRow = [];
                            bodyRow.push({
                                content: i,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.CUSTOMER_NUMBER,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.CUSTOMER_NAME,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.KWT_NUMBER,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.KWT_DATE,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.JENIS_KWT,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: this.formatCurrency(element.DPP_AMOUNT),
                                styles: { valign: 'middle', halign: 'right' }
                            })
                            bodyRow.push({
                                content: this.formatCurrency(element.PPH_AMOUNT),
                                styles: { valign: 'middle', halign: 'right' }
                            })
                            bodyRow.push({
                                content: element.TARIF,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            bodyRow.push({
                                content: element.UMUR_KWITANSI,
                                styles: { valign: 'middle', halign: 'center' }
                            })
                            // console.log(element.PPH_AMOUNT);
                            grand_total_dpp = grand_total_dpp + element.DPP_AMOUNT;
                            grand_total_pph = grand_total_pph + parseInt(element.PPH_AMOUNT);
                            dataRow.push(bodyRow);
                            i++;
                        });
                        const logo = require('@/assets/indogrosir.png')
                        var imgLogo = new Image()
                        imgLogo.src = logo
                        // var img3 = new Image();
                        // img3.url = '../../../assets/indogrosir.png';
                        // doc.text(header, 13, 5, { baseline: 'middle' });
                        var startDate = new Date(this.start_date);
                        var endDate = new Date(this.end_date);
                        var doc = new jsPDF();
                        var now = new Date();
                        // var year = now.getFullYear();
                        var month = now.getMonth() + 1;
                        var day = now.getDate();
                        var hour = now.getHours();
                        var minute = now.getMinutes();
                        var second = now.getSeconds();
                        if (month.toString().length == 1) {
                            month = '0' + month;
                        }
                        if (day.toString().length == 1) {
                            day = '0' + day;
                        }
                        if (hour.toString().length == 1) {
                            hour = '0' + hour;
                        }
                        if (minute.toString().length == 1) {
                            minute = '0' + minute;
                        }
                        if (second.toString().length == 1) {
                            second = '0' + second;
                        }
                        var dateTime = now.toLocaleDateString('en-GB', {
                            day: 'numeric', month: 'short', year: 'numeric'
                        }).replace(/ /g, '-');
                        var getWaktu = hour + ':' + minute + ':' + second;
                        var concatNamaKodeSupplier = this.cariKodeNamaSupplier(this.dataForm.nomorCustomer);
                        // console.log(concatNamaKodeSupplier);
                        if (!concatNamaKodeSupplier) {
                            concatNamaKodeSupplier = 'Semua'
                        }
                        // console.log(year);
                        // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
                        doc.setFont("helvetica", "italic");
                        // doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
                        doc.setFontSize(7);
                        doc.text("Taxation HO Igr.", 10, 10);
                        doc.text("Tgl. Cetak:" + dateTime, 180, 10);
                        doc.text("Pkl. Cetak:" + getWaktu, 180, 13);
                        doc.text("User Id:" + this.user.username, 180, 16);
                        doc.getFontList();
                        doc.setFont("helvetica", "bold");
                        doc.setFontSize(12);
                        doc.text("Laporan Monitoring Outstanding Penyerahan BuPot", 105, 22, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        doc.setFontSize(8);
                        if (this.dataForm.tipePPh === 'pphFinal') {
                            doc.text("(PPh Final) *", 105, 26, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else if (this.dataForm.tipePPh === 'pph23') {
                            doc.text("(PPh ps. 23) *", 105, 26, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        } else {
                            doc.text("(PPh ps. 23/PPh Final) *", 105, 26, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        }
                        doc.setFont("helvetica", "normal");
                        doc.setFontSize(8);
                        // doc.text("(cetakan/tampilan = semua transaksi/hanya yang outstanding) *", 105, 29, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        // doc.text("Pilihan Proses : (Pengajuan refund PPh/Penyerahan BuPot) *", 105, 32, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        doc.text("Kode - Nama Supplier : " + concatNamaKodeSupplier, 105, 29, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        doc.text("Periode : " + startDate.toLocaleDateString('en-GB', {
                            day: 'numeric', month: 'short', year: 'numeric'
                        }).replace(/ /g, '-') + " s/d " + endDate.toLocaleDateString('en-GB', {
                            day: 'numeric', month: 'short', year: 'numeric'
                        }).replace(/ /g, '-'), 105, 32, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        // doc.text("(cetakan/tampilan = semua transaksi/hanya yang outstanding) *", 105, 29, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        // doc.text(judulPPH + " KE PIHAK FINANCE", 105, 58, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                        doc.setFont("helvetica", "normal");
                        doc.setFontSize(7);
                        doc.autoTable({
                            styles: {
                                fontSize: 10
                            },
                            columnStyles: {
                                0: { cellWidth: 10, fontSize: 7 },
                                1: { cellWidth: 12, fontStyle: 'bold', fontSize: 7, textColor: 'black' },
                                2: { halign: 'center', cellWidth: 35, fontSize: 7, textColor: 'black' },
                                3: { cellWidth: 18, fontSize: 7, halign: 'right', textColor: 'black' },
                                4: { cellWidth: 25, fontSize: 7, halign: 'right', textColor: 'black' },
                                5: { cellWidth: 28, fontSize: 7, halign: 'right', textColor: 'black' },
                                6: { cellWidth: 25, fontSize: 7, halign: 'right', textColor: 'black' },
                                7: { halign: 'center', cellWidth: 25, fontSize: 7, textColor: 'black' },
                                8: { halign: 'center', fontSize: 7, cellWidth: 10, textColor: 'black' },
                                9: { halign: 'right', cellWidth: 20, fontSize: 7, textColor: 'black' }
                            },
                            headStyles: {
                                lineWidth: 0.5,
                                lineColor: [0, 0, 0],
                                fillColor: [255, 255, 255],
                                textColor: 'black'
                            },
                            theme: 'grid',
                            head: [[
                                { content: 'No ', rowSpan: 2, styles: { halign: 'center', valign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Customer ', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Kwitansi ', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Jenis Transaksi', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Nilai (Rp.)', colSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: '(%)', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Umur Kwitansi', rowSpan: 2, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                            ], [
                                { content: 'Kode', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Nama', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Nomor', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'Tanggal Pelunasan', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'DPP', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: 'PPH', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } }
                            ],],
                            bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                            startY: 38,
                            margin: { top: 5, left: 1, right: 1 },
                            body: dataRow,
                            footStyles: {
                                lineWidth: 0.5,
                                lineColor: [0, 0, 0],
                                fillColor: [255, 255, 255],
                                textColor: 'black'
                            },
                            rowPageBreak: 'auto'
                        })
                        let finalY = doc.lastAutoTable.finalY;
                        doc.setFont("helvetica", "bold");
                        doc.autoTable({
                            columnStyles: {
                                0: { cellWidth: 128, fontSize: 7 },
                                1: { cellWidth: 25, fontSize: 7 },
                                2: { cellWidth: 20, fontSize: 7 },
                                3: { cellWidth: 35, fontSize: 7 },
                            },
                            bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.5, fillColor: [255, 255, 255] },
                            body: [[
                                { content: 'Jumlah', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9, fontStyle: 'bold' } },
                                { content: this.formatCurrency(grand_total_dpp), colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: this.formatCurrency(grand_total_pph), colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black' } },
                                { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black' } }
                            ]],
                            margin: { top: 5, left: 1, right: 1 },
                            startY: finalY,
                            lineColor: [0, 0, 0]
                        })
                        window.open(doc.output('bloburl'));
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error.response.data.message)
                        this.$swal.fire(
                            'Peringatan!',
                            'Data yang anda cari tidak ditemukan!',
                            'warning'
                        )
                        this.loading = false;
                    })
            }
        }
    }
}

</script>
